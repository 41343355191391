import request from '@/utils/request'

// 订单列表
export function orders_index(data) {
  return request({
    url: '/merchant/orders/index',
    method: 'get',
    params: data
  })
}
// 订单明细(商品)列表
export function orders_item(data) {
  return request({
    url: '/merchant/orders/orderItem',
    method: 'get',
    params: data
  })
}

// 待发货订单列表
export function orders_unready_index(data) {
  return request({
    url: '/merchant/orders/unready',
    method: 'get',
    params: data
  })
}

// 订单详情
export function orders_read(data) {
  return request({
    url: '/merchant/orders/read',
    method: 'get',
    params: data
  })
}
// 修改发货地址
export function orders_consignee(data) {
  return request({
    url: '/merchant/orders/consignee',
    method: 'post',
    data: data
  })
}
// 修改订单备注
export function orders_remark(data) {
  return request({
    url: '/merchant/orders/remark',
    method: 'post',
    data: data
  })
}
// 发货
export function orders_delivery(data) {
  return request({
    url: '/merchant/orders/delivery',
    method: 'post',
    data: data
  })
}

// 开单
export function orders_doReady(data) {
  return request({
    url: '/merchant/orders/doReady',
    method: 'post',
    data: data
  })
}

// 地址确认
export function orders_addressConfirm(data) {
  return request({
    url: '/merchant/orders/doAddressConfirm',
    method: 'post',
    data: data
  })
}

// 取消发货
export function orders_cancelDelivery(data) {
  return request({
    url: '/merchant/orders/cancelDelivery',
    method: 'post',
    data: data
  })
}

// 订单排行榜列表
export function orders_order_rank(data) {
  return request({
    url: '/merchant/orders/orderRank',
    method: 'get',
    params: data
  })
}

// 退单审核
export function cancel_confirm(data) {
  return request({
    url: '/merchant/orders/cancelConfirm',
    method: 'post',
    data: data
  })
}

// 获取特殊充值记录
export function special_recharge_index(data) {
  return request({
    url: '/merchant/SpecialRecharge/index',
    method: 'get',
    params: data
  })
}

// 添加特殊充值记录
export function special_recharge_add(data) {
  return request({
    url: '/merchant/SpecialRecharge/add',
    method: 'post',
    data: data
  })
}